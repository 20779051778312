import * as React from "react"
import {Box, Button, Heading, Paragraph, Text} from "grommet";
import Seo from "../components/Seo";
import Layout from "../components/Layout";

const TermsAndConditionsPage = () => {
    return (
        <Layout content={<Box width={{max: '1200px'}} height={{min: '600px'}} justify='center' animation='slideRight'>
            <Heading level={1} size='large' color='white'>Сетивно изживяване от друго измерение</Heading>
            <Button label={<Text weight='bold' size='xlarge'>Запази час</Text>} primary size='large' alignSelf='start'
                    href='/#join-in'/>
        </Box>}>
            <Seo title="Общи условия"/>
            <Box alignSelf='center' width={{max: 'large'}}>
                <Heading alignSelf='center'>Общи условия</Heading>
                <Box alignSelf='center'>
                    <Box>
                        <Heading level={2}>Предмет</Heading>
                        <Paragraph fill>
                            Чл. 1. Настоящите общи условия са предназначени за регулиране на отношенията между
                            „Митабитс“ ЕООД, наричано по-долу за краткост ДОСТАВЧИК, и потребителите, наричани по-долу
                            ПОЛЗВАТЕЛИ, на предоставяната от него услуги на информационното общество, наричани по-долу
                            УСЛУГАТА.
                        </Paragraph>
                    </Box>
                    <Box>
                        <Heading level={2}>Данни за доставчика</Heading>
                        <Paragraph fill>
                            Чл. 2. Информация съгласно Закона за електронната търговия и Закона за защита на
                            потребителите:
                        </Paragraph>
                        <ol>
                            <li>Наименование на Доставчика: „Митабитс“ ЕООД</li>
                            <li>Седалище и адрес на управление: гр. Пловдив, ул. „Капитан Райчо 56“</li>
                            <li>Адрес за упражняване на дейността: гр. Пловдив, ул. „Капитан Райчо 56“</li>
                            <li>Данни за кореспонденция:</li>
                            <ul>
                                <li>Email: info@mainaverse.com</li>
                                <li>Телефонен номер: 0879 203 242</li>
                                <li>Адрес: гр. Пловдив, ул. “Капитан Райчо” 56</li>
                                <li>Уеб-сайт: https://mainaverse.com</li>
                            </ul>

                        </ol>
                    </Box>
                    <Box>
                        <Heading level={2}>Характеристики на услугата</Heading>
                        <Paragraph fill>
                            Чл. 3. Услугата, предоставяна от Доставчика на Ползвателя представлява услуга на
                            информационното общество по смисъла на Закона за електронната търговия. Основните
                            характеристики на Услугата са следните:
                        </Paragraph>
                        <ul>
                            <li>Запазване на час за развлекателна игра</li>
                            <li>Получаване на информация относно свободни часове за игра</li>
                            <li>Запазване на час за тиймбилдинг</li>
                            <li>Запазване на час за рожден ден</li>
                            <li>Получаване на информация относно себестойност на услугата</li>
                        </ul>
                        <Paragraph fill>Чл. 4. Доставчикът предоставя, а Ползвателите използват Услугата, съгласно
                            параметрите, обявени на страницата на Доставчика в Интернет.</Paragraph>
                        <Heading level={3}>Цена на услугата</Heading>
                        <Paragraph fill>Чл. 5. (1) Доставчикът предоставя Услугата срещу възнаграждение, дължимо от
                            Ползвателя съгласно избрания от него абонаментен план, когато Услугата се предоставя
                            възмездно.<br/><br/>
                            (2) Информация за различните параметри на Услугата е достъпна на страницата на Доставчика в
                            Интернет.</Paragraph>
                        <Paragraph fill>Чл. 6. (1) Ползвателят заплаща цената на Услугата съобразно определеното от
                            Доставчика в страницата му в Интернет, когато Услугата се предоставя възмездно.<br/><br/>
                            (2) Доставчикът потвърждава получаването на плащането чрез активиране на Услугата и чрез
                            друго подходящо потвърждение по електронен път.</Paragraph>
                        <Heading level={3}>Предоставяне на услугата и технически стъпки за сключване на
                            договора</Heading>
                        <Paragraph fill>Чл. 7 (1) Настоящите общи условия се прилагат както към услуги, за които се
                            изисква регистрация, така и за такива, за които не се изисква регистрация.<br/><br/>
                            (2) За да използва Услугата, Ползвателят следва да въведе избрана от него парола за
                            отдалечен достъп.<br/><br/>
                            (3) Паролата за отдалечен достъп се определя от Ползвателя, чрез извършване на онлайн
                            регистрация в страницата на Доставчика в Интернет, съобразно посочената в нея и настоящите
                            общи условия процедура.<br/><br/>
                            (4) С попълване на данните си и натискане на бутоните “Да, приемам”, “Регистрация”,
                            “Резервация” или друго изявление в смисъл на съгласие, Ползвателят декларира, че е запознат
                            с тези общи условия, съгласен е с тяхното съдържание и се задължава безусловно да ги спазва.<br/><br/>
                            (5) Доставчикът потвърждава извършената от Ползвателя регистрация или резервация, чрез
                            изпращане на писмо на посочен от Ползвателя електронен адрес, на който се изпращат и
                            информация за активиране на регистрацията. Получателят потвърждава регистрацията и
                            сключването на договора чрез електронна препратка в писмото, с което е уведомен за
                            извършената регистрация, изпратено от Доставчика.След потвърждаването се създава акаунт на
                            Ползвателя и между него и Доставчика възникват договорни отношения.<br/><br/>
                            (6) При извършване на регистрацията Ползвателят се задължава да предостави верни и актуални
                            данни. Ползвателят своевременно да актуализира данните, посочени в регистрацията си, в
                            случай на промяна.<br/><br/>
                            (7) В случай че за регистрация на Ползвателя за ползване на услугата се използва профил в
                            уеб социални мрежи или други мрежи, страна по договора е лицето, което е титуляр на
                            използвания за регистрацията профил в съответната социална или друга мрежа. В този случай
                            Доставчикът има право на достъп до данните, необходими за идентифициране на Ползвателя в
                            съответната социална или друга мрежа.</Paragraph>
                        <Paragraph fill>Чл. 8. (1) Ползвателите използват предимно интерфейса на Интернет страницата на
                            Доставчика, за извършване на електронни изявления в отношенията помежду им.<br/><br/>
                            (2) Договорът се сключва на български език.<br/><br/>
                            (3) Договорът между Доставчика и Ползвателя представлява настоящите общи условия, достъпни
                            на адрес https://mainaverse.com, заедно с всички изменения и допълнения в тях.<br/><br/>
                            (4) Страна по договора с Доставчика е Ползвателят на Услугата съгласно данните, предоставени
                            при регистрацията и съдържащи се в личния профил на Ползвателя. За избягване на съмнение
                            това са данните, с които е създаден акаунт при Доставчика.<br/><br/>
                            (5) Доставчикът включва в интерфейса на Интернет страницата си, технически средства за
                            установяване и поправяне на грешки при въвеждането на информация, преди да бъде направено
                            изявлението за сключване на договора.<br/><br/>
                            (6) Този договор се счита за сключен от момента на извършване на регистрация на Ползвателя
                            при Доставчика. В случай че Услугата се заявява за ползване след извършване на
                            регистрацията, договорът за използването й се счита за сключен от момента на заявяването й
                            от Ползвателя чрез интерфейса на Доставчика.<br/><br/>
                            (7) За сключването на този договор и за сключването на договора за използване на Услугата,
                            Доставчикът изрично уведомява Ползвателя по подходящ начин чрез електронни
                            средства.<br/><br/>
                            (8) В случай, че Услугата се използва без извършване на регистрация от Ползвателите,
                            договорът за използването й се счита за сключен от момента на първото й използване от
                            Ползвателя. В този случай настоящите общи условия имат действия от момента на първото
                            използване на услугата от Ползвателя до момента на преустановяване на използването
                            й.<br/><br/>
                            (9) Изявлението за сключване на договора и потвърждението за неговото получаване се смятат
                            за получени, когато техните адресати имат възможност за достъп до тях. </Paragraph>
                        <Paragraph fill>
                            Чл. 9. (1) Ползвателят има право да използва Услугата добросъвестно и по
                            предназначение.<br/><br/>
                            (2) При използването на Услугата, Ползвателят не трябва да използва софтуер, скриптове,
                            програмни езици или други технологии, които биха могли да създадат затруднения в ползването
                            й от другите ползватели.<br/><br/>
                            (3) Ползвателят се задължава да не използва Услугата в противоречие с приложимото
                            законодателство.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 10. Ползвателят осигурява самостоятелно оборудването за достъп до Услугата и нейното
                            управление.
                        </Paragraph>
                        <Paragraph fill>Чл. 11. (1) С оглед повишаване качеството на Услугата, извършване на
                            профилактика, отстраняване на повреди и други свързани с това дейности, Доставчикът има
                            право временно да ограничи или преустанови предоставянето на услугата.<br/><br/>
                            (2) В случаите по ал. 1, Доставчикът е задължен своевременно да възстанови предоставянето на
                            услугата след отпадане на обстоятелството, което е причина за преустановяването.</Paragraph>
                        <Paragraph fill>
                            Чл. 12. (1) Доставчикът предприема мерки за защита на личните данни на Ползвателя съгласно
                            Закона за защита на личните данни.<br/><br/>
                            (2) От съображения за сигурност на личните данни на Ползвателите, Доставчикът ще изпрати
                            данните само на e-mail адрес, който е бил посочен от Ползвателите в момента на
                            регистрацията.<br/><br/>
                            (3) Доставчикът приема и обявява на сайта си Политика за защита на личните данни, достъпна
                            на адрес https://mainaverse.com. Ползвателят се съгласява с Политиката за защита на личните
                            данни на Доставчика.<br/><br/>
                            (4) Получателят се съгласява, че Доставчикът има право да обработва личните му данни
                            съгласно Политиката му за защита на личните данни.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 13. (1) Във всеки момент преди, по време или след предоставяне на Услугата, Доставчикът
                            има право да изисква от Ползвателя да се легитимира и да удостовери достоверността на всяко
                            едно от обявените по време на регистрацията обстоятелства и лични данни.
                        </Paragraph>
                        <Heading level={3}>Изменения и достъп до общите условия</Heading>
                        <Paragraph fill>
                            Чл. 14. (1) Настоящите общи условия могат да бъдат изменяни от Доставчика, за което
                            последният ще уведоми по подходящ начин всички регистрирани Ползватели на
                            услугата.<br/><br/>
                            (2) Доставчикът и Ползвателят се съгласяват, че всяко допълване и изменение на тези общи
                            условия ще има действие спрямо Ползвателя след изричното му уведомяване от Доставчика и ако
                            Ползвателят не заяви в предоставения му 14-дневен срок, че ги отхвърля.<br/><br/>
                            (3) Ползвателят се съгласява, че всички изявления на Доставчика, във връзка с изменението на
                            тези общи условия ще бъдат изпращани на адреса на електронната поща, посочена от Ползвателя,
                            при регистрацията за използване на Услугата. Ползвателят на Услугата се съгласява, че
                            електронните писма, изпратени по реда на този член не е необходимо да бъдат подписани с
                            електронен подпис, за да имат действие спрямо него.<br/><br/>
                            (4) Ползвателите, които използват Услугата без регистрация приемат новите общи условия от
                            момента на използването след промяната им, без изрично уведомление за промяната.
                        </Paragraph>
                    </Box>
                    <Box>
                        <Heading level={2}>Прекратяване</Heading>
                        <Text>Чл. 15. Договорът за предоставяне на Услугата се прекратява:</Text>
                        <ul>
                            <li>с изтичане на срока на договора, съгласно избрания от Ползвателя период на предоставяне
                                на Услугата
                            </li>
                            <li>с прекратяване на регистрацията на Ползвателя за ползване на Услугата</li>
                            <li>при прекратяване и обявяване в ликвидация или обявяване в несъстоятелност на една от
                                страните по договора
                            </li>
                            <li>по взаимно съгласие на страните в писмен вид</li>
                            <li>едностранно с предизвестие от всяка от страните в случай на неизпълнение на задълженията
                                на другата страна
                            </li>
                            <li>при обективна невъзможност на някоя от страните по договора да изпълнява задълженията
                                си
                            </li>
                            <li>при изземване или запечатване на оборудването от държавни органи</li>
                            <li>в случаите по чл. 9, ал. 3 от тези общи условия</li>
                        </ul>
                        <Paragraph fill>
                            Чл. 16. Доставчикът има право по свое усмотрение, без да отправя предизвестие да прекрати
                            едностранно договора, в случай че установи, че предоставяните услуги се използват в
                            нарушение на настоящите общи условия, законодателството в Република България и общоприетите
                            нравствени норми.
                        </Paragraph>
                    </Box>
                    <Box>
                        <Heading level={2}>Политика на плащанията</Heading>
                        <Paragraph fill>
                            Чл. 17. Клиентът има възможност да заплати предоставената от “Митабитс” ЕООД услуга, в един
                            от следните варианти: банков превод, плащане на място, плащане чрез виртуален ПОС
                            терминал(плащане с банкова карта)
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 18. Всеки клиент може да се откаже от направената от него поръчка за услуга,
                            предоставена от “Митабитс” ЕООД, не по-късно от 48 часа преди часа за посещение. При отказ
                            от поръчка в регламентирания срок, клиентът не дължи такса.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 19. При отказ от направена поръчка до 48 часа преди предоставяне на услугата, в случай,
                            че плащането е извършено чрез банкова карта, “Митабитс” ЕООД възстановява заплатените суми
                            чрез нареждане на обратна операция по картата, с която е направено плащането в срок от 3
                            работни дни.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 20. В случай, че клиентът се откаже от услугата в срок под 48 часа, то той дължи
                            неустойка в размер на <strong>20.00 лв</strong>.
                        </Paragraph>
                    </Box>
                    <Box>
                        <Heading level={2}>Допълнителни условия</Heading>
                        <Paragraph fill>
                            Чл. 21. Със съгласие за резервация при друг организатор, направена от представител на
                            “Митабитс” ЕООД, ще се счита, че сте се съгласили автоматично с направата на резервацията, с
                            Общите Условия и на другия организатор, като декларирате, че ще се запознаете с тях от сайта
                            на съответният организатор или на място при посещението си.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 22. За неуредените в този договор въпроси, свързани с изпълнението и тълкуването на този
                            договор, се прилагат законите на Република България.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 23. Всички спорове между страните по този договор ще бъдат разрешавани от компетентния
                            съд или Комисията за защита на потребителите.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 24. Настоящите общи условия влизат в сила за всички Ползватели на 01.01.2022 г.
                        </Paragraph>
                        <Paragraph fill>
                            Чл. 25. Евентуалната недействителност на някоя от разпоредбите на тези общи условия няма да
                            води до недействителност на целия договор.
                        </Paragraph>
                    </Box>
                </Box>
            </Box>

        </Layout>
    )
}

export default TermsAndConditionsPage
